
import { Component, namespace, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import BreadCrumb from "~/components/molecules/display/BreadCrumb.vue";
import FaqAnswer from "~/components/molecules/display/FaqAnswer.vue";
import SortHeader from "~/components/molecules/nav/SortHeader.vue";
import AkkordeonListItem from "~/components/molecules/text/AkkordeonListItem.vue";
import DealerOfferItem from "~/components/organisms/display/DealerOfferItem.vue";
import PriceComparisonH1 from "~/components/organisms/display/PriceComparisonH1.vue";
import PriceComparison from "~/components/organisms/form/PriceComparison.vue";

import { translateErrorMessage } from "~/defaults/heizoelpreise/errorCodeMessages";
import { generateMetaTags } from "~/operations/meta-helper-functions";
import { BreadCrumbDetail } from "~/types/BreadCrumb";

const CalculatorModule = namespace("calculator");

@Component({
  components: {
    BreadCrumb,
    AkkordeonListItem,
    FaqAnswer,
    PriceComparisonH1,
    PriceComparison,
    SortHeader,
    DealerOfferItem,
    RouterLink,
  },
  methods: {
    translateErrorMessage,
  },
})
export default class PelletsLandingPage extends Vue {
  layout(context) {
    const layout = context.query.amount ? "shop" : "default";
    return layout;
  }
  head() {
    return {
      ...generateMetaTags(this.$route.path, "pellets"),
      link: [{ rel: "canonical", href: `${this.$route.fullPath}` }],
    };
  }

  translateErrorMessage = translateErrorMessage;

  QuestionsList = [
    {
      question: "Welche Vorteile & Nachteile bietet das Heizen mit Pellets?",
      answer: `
<div style="margin-bottom: 20px;">
<h3>
  <strong style="font-weight: 900;">Vorteile:</strong>
</h3>
<ul style="padding-left: 20px; list-style-type: none;">
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Nachwachsender & regionaler Rohstoff
  </li>
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> CO₂-Kreislauf ist nahezu neutral
  </li>
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Energieaufwand für die Herstellung ist gering
  </li>
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Niedrigere Betriebskosten im Vergleich zu Öl und Gas
  </li>
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Relativ stabile Preise
  </li>
</ul>
</div>

<h3>
  <strong style="font-weight: 900;">Nachteile:</strong>
</h3>
<ul style="padding-left: 20px; list-style-type: none;">
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Hohe Anschaffungskosten der Heiztechnik
  </li>
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Großer Lagerraum für Pellets wird benötigt
  </li>
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Regelmäßige Reinigung und Wartung sind erforderlich
  </li>
  <li style="position: relative;">
    <span style="position: absolute; font-size: 20px; left: -15px; top: -4px;">•</span> Mögliche Feinstaubemissionen
  </li>
</ul>
      `,
      visibility: true,
    },
    {
      question: "Wie viele Haushalte nutzen Pellets?",
      answer:
        "Bis Ende 2023 gab es rund 722.000 Pelletsanlagen und für das Jahr 2024 wird mit einem Anstieg auf 763.000 Anlagen gerechnet.",
      visibility: true,
    },
    {
      question: "Wie werden Pellets hergestellt?",
      answer:
        "Der Herstellungsprozess beginnt mit der sorgfältigen Auswahl von Holzfasern. Diese stammen entweder aus nachhaltig bewirtschafteten Wäldern oder aus Holzabfällen. Zunächst werden die Rohstoffe zerkleinert, getrocknet und zu einem feinen Pulver gemahlen, um eine gleichmäßige Textur zu erzielen. Dieses feine Holzpulver wird dann unter hohem Druck zusammengepresst. Das im Holz enthaltene natürliche Lignin wirkt dabei als Bindemittel. Dies führt zur Produktion dichter und gleichmäßiger Pellets, die komplett ohne Zusatzstoffe oder Chemikalien auskommen. Nach der Pressung durchlaufen die Pellets einen Kühl- und Siebprozess, der sicherstellt, dass sie den Qualitätsstandards entsprechen. Das Endergebnis ist eine saubere, erneuerbare Energiequelle, die effizient verbrennt, Abfall reduziert und die Kohlenstoffemissionen verringert.",
      visibility: true,
    },
  ];

  ComparisonList = [
    {
      title: "Silowagen",
      pros: [
        "Günstigster Preis",
        "Komfortable Lieferung direkt in eigenen Lagerraum",
        "Kein Kraftaufwand beim Einlagern nötig",
        "Direkte Befüllung möglich",
        "Staubarme Lieferung durch Staubschutz möglich",
      ],
      cons: [
        "Benötigt speziellen Lagerraum",
        "Liefermöglichkeit wird begrenzt durch maximale Schlauchlänge",
        "Zufahrt für großes Silofahrzeug notwendig",
        "Potenziell mehr Staubbildung beim Einblasen",
      ],
    },
    {
      title: "Paletten",
      pros: [
        "Platzsparende Lagerung",
        "Können in kleineren Mengen gekauft und gelagert werden",
        "Verursacht weniger Staub und Schmutz bei Lagerung und Handhabung",
        "Ermöglicht manuelle Befüllung des Pelletofens nach Bedarf",
      ],
      cons: [
        "Körperliche Anstrengung bei Befüllung des Pelletofens",
        "Verursachen Verpackungsmüll",
        "Zufahrt muss mit einem Hubwagen befahrbar sein",
        "Begrenzte Menge pro Palette",
        "Höhere Kosten im Vergleich zu losen Pellets",
      ],
    },
    {
      title: "Big Bag",
      pros: [
        "Sehr platzsparende Lagerung",
        "Einfach zu lagern, da formstabil und nahezu quadratisch",
        "Keine Lagerung im Keller zwingend notwendig",
        "Big Bags sind UV- und regenbeständig",
      ],
      cons: [
        "Hohes Gewicht",
        "In der Regel teurer als lose Pellets",
        "Zufahrt muss mit einem Hubwagen befahrbar sein",
        "Big Bag enthält nur begrenzte Menge an Pellets (in der Regel 1000 kg)",
      ],
    },
  ];

  get breadCrumbData(): BreadCrumbDetail[] {
    return [
      {
        lastItem: true,
        linkUrl: "/pelletspreise",
        linkValue: "/pelletspreise",
      },
    ];
  }

  changeFaqState(item, beforeOrder) {
    if (beforeOrder) {
      this.QuestionsList.map((elem) => {
        if (elem.question === item.question) {
          elem.visibility = !elem.visibility;
        }
        return elem;
      });
    } else {
      this.QuestionsList.map((elem) => {
        if (elem.question === item.question) {
          elem.visibility = !elem.visibility;
        }
        return elem;
      });
    }
  }
}
